import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "domain-name-server-dns-cname-alias"
    }}>{`Domain Name Server (DNS) CNAME Alias`}</h1>
    <p>{`The library can use a domain that they have registered rather than the assigned domain provided.`}</p>
    <p>{`For example, Wheeljack Public Library’s hosted calendar might default to a provided URL of wheeljack.events.mylibrary.digital, but library wishes to have the public instead see a subdomain of their existing site `}<a parentName="p" {...{
        "href": "http://www.wjlib.info"
      }}>{`http://www.wjlib.info`}</a>{`, e.g. `}<a parentName="p" {...{
        "href": "http://calendar.wjlib.info."
      }}>{`http://calendar.wjlib.info.`}</a>{` `}</p>
    <p>{`To facilitate this, the library needs to create 2 DNS records.`}</p>
    <ol>
      <li parentName="ol">{`A DNS CNAME record for the address they want the platform to appear under. In the previous example, if they have the domain wjlib.info and they want the platform to be located at calendar.wjlib.info, then they need to create a DNS CNAME record for “calendar” that points to the platform-provided events address (e.g. wheeljack.events.mylibrary.digital) `}</li>
      <li parentName="ol">{`A custom DNS record that allows for the generation of SSL certificates automatically with our platform hosting provider. The necessary information is different for each library/domain and will be provided during the implementation phase. `}</li>
    </ol>
    <p>{`Using the example above, Wheeljack Public Library would be provided information like the following, where each x represents a unique set of alphanumeric or special characters generated by our platform (note that the actual Name and Value content will be different).`}</p>
    <p>{`Record Type: CNAME
Record Name: `}<em parentName="p">{`xxxxxxxxxxxxxxxxxxxxx.calendar.wjlib.info
Record Value: `}</em>{` xxxxxxxxxxxxxxxxxxxxx.acm-validations.aws`}</p>
    <p>{`With those two records in place, the library will be able to use the URL from their own domain (e.g. calendar.wjlib.info) and load our hosted calendar platform seamlessly so that it appears to be a part of the library’s own website.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      